import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {

  private VALIDATE_PAN = 'api/validate-pan-v1';
  private CREATE_USER = 'api/create-user';
  private ADD_BANK_DETAILS = 'api/bank';
  private VALIDATE_BANK_DETAILS = 'api/validate-bank';
  private ADD_ADDRESS = 'api/update-address';
  private ADD_NOMINEE = 'api/add-nominee';
  private UPLOAD_AGGREMENT = 'api/esign-agreement';
  private GET_USER = 'api/get-user';
  private GET_REPORTS = 'api/get-reports';
  private GET_PINCODE_DETAILS = 'api/get-pincode-details';
  private GET_AGREEMENT = 'api/get-agreement-link';
  private GET_INVESTOR_DETAILS = 'api/get-investor-details'

  private options = {
    headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
  };

  HTTPOptionsForText: Object = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    responseType: 'text'
  }

  constructor(private http: HttpClient) { }

  validatePAN(pan: string) {
    const data = {
      pan: pan
    }
    return this.http.post(environment.base_url + this.VALIDATE_PAN, data, this.options);
  }

  registerUser(data: any) {
    return this.http.post(environment.base_url + this.CREATE_USER, data, this.options);
  }

  addBankDetails(data: any) {
    return this.http.post(environment.base_url + this.ADD_BANK_DETAILS, data, this.options);
  }

  validateBankDetails(data: any) {
    return this.http.post(environment.base_url + this.VALIDATE_BANK_DETAILS, data, this.options);
  }

  addAddress(data: any) {
    return this.http.post(environment.base_url + this.ADD_ADDRESS, data, this.options);
  }

  addNominee(data: any) {
    return this.http.post(environment.base_url + this.ADD_NOMINEE, data, this.options);
  }

  uploadAggrement(data: any) {
    return this.http.post(environment.base_url + this.UPLOAD_AGGREMENT, data, this.options);
  }

  getAgreement() {
    return this.http.get(environment.base_url + this.GET_AGREEMENT, this.HTTPOptionsForText);
  }

  getInvestorAgreement(investorId: any) {
    return this.http.get(environment.base_url + this.GET_AGREEMENT + "?id=" + investorId, this.HTTPOptionsForText);
  }

  getUser() {
    return this.http.get(environment.base_url + this.GET_USER, this.options);
  }

  getReports(question_id : any){
    return this.http.get(environment.base_url + this.GET_REPORTS + "?question_id=" + question_id, this.options);
  }

  getInvestorProfile(id: any) {
    return this.http.get(environment.base_url + this.GET_INVESTOR_DETAILS + '?id=' + id, this.options).toPromise();
  }

  fetchPincodeDetails(data: any) {
    return this.http.get(environment.base_url + this.GET_PINCODE_DETAILS + '?pincode=' + data.pincode, this.options);
  }
}
